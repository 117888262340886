<template>
  <div class="page-box page-contact bg-white">
    <ban-view :banStyle="banStyle"></ban-view>

    <div class="bxb-con contact-content bxb-p50">
      <h6>百色市远兰小额贷款有限责任公司</h6>
      <div class="list">
        <p>客服热线：<b>086-0776-2624266</b></p>
        <p>官方邮箱：<b>yuanlanxiaodai@outlook.com</b></p>
        <p>详细地址：<b>广西壮族自治区百色市右江区城东大道136号1楼1号</b></p>
        <p>邮编：<b>533000</b></p>
        <p>市场合作：<b>yuanlanxiaodai@outlook.com</b></p>
      </div>
    </div>
  </div>
</template>

<script>
import BanView from "components/content/banView/BanView";
export default {
  name: "Contact",
  components: {
    BanView
  },
  data() {
    return {
      banStyle: {
        height: "300px",
        backgroundImage: 'url("https://ylxd.xcongzb.com/img/ban-contact.jpg")'
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.contact-content {
  h6 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .list {
    p {
      padding: 10px 0;
      font-size: 18px;
      color: #777;
      b {
        padding-left: 10px;
        color: #333;
      }
    }
  }
}
</style>
